<script setup lang="ts">
import type { PrimitiveProps } from 'radix-vue'
import { type HTMLAttributes, computed, ref } from 'vue'
import { useElementSize, useResizeObserver, useScroll } from '@vueuse/core'
import type { ButtonVariants } from '~/components/ui/button'
import { config } from '~/constants'

interface IButton extends PrimitiveProps {
  variant?: ButtonVariants['variant']
  size?: ButtonVariants['size']
  class?: HTMLAttributes['class']
}

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<{
  items?: any[]
  arrows?: boolean
  indicators?: boolean
  prevButton?: IButton
  prevButtonIcon?: string
  nextButton?: IButton
  nextButtonIcon?: string
  class: string | Record<string, unknown> | (string | Record<string, unknown>)[]
  ui?: Partial<typeof config.carousel>
}>(), {
  items: () => [],
  arrows: false,
  indicators: false,
  prevButton: () => ({ ...config.carousel.default.prevButton }),
  prevButtonIcon: 'ph:caret-left-bold',
  nextButton: () => ({ ...config.carousel.default.nextButton }),
  nextButtonIcon: 'ph:caret-right-bold',
  class: '',
  ui: undefined,
})

const attrs = useAttrs()

const carouselRef = ref<HTMLElement>()
const itemWidth = ref(0)

const { x } = useScroll(carouselRef, { behavior: 'smooth' })

const { width: carouselWidth } = useElementSize(carouselRef)

useCarouselScroll(carouselRef as Ref<HTMLElement>)

useResizeObserver(carouselRef, (entries) => {
  const [entry] = entries

  itemWidth.value = entry?.target?.firstElementChild?.clientWidth || 0
})

const currentPage = computed(() => {
  if (!itemWidth.value) {
    return 0
  }

  return Math.round(x.value / itemWidth.value) + 1
})

const pages = computed(() => {
  if (!itemWidth.value) {
    return 0
  }

  return props.items.length - Math.round(carouselWidth.value / itemWidth.value) + 1
})

const isFirst = computed(() => currentPage.value <= 1)
const isLast = computed(() => currentPage.value === pages.value)

const hasOverflow = computed(() => {
  return carouselWidth.value < itemWidth.value * props.items.length
})

function onClickNext() {
  x.value += itemWidth.value
}

function onClickPrev() {
  x.value -= itemWidth.value
}

function onClick(page: number) {
  x.value = (page - 1) * itemWidth.value
}

defineExpose({
  pages,
  page: currentPage,
  prev: onClickPrev,
  next: onClickNext,
  select: onClick,
})
</script>

<template>
  <div :class="cn('relative', ui?.wrapper, props.class)" v-bind="attrs">
    <div
      ref="carouselRef"
      :class="cn('relative w-full flex overflow-x-auto snap-x snap-mandatory scroll-smooth', ui?.container)"
      class="no-scrollbar"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="cn('flex flex-none snap-center', ui?.item)"
        :role="indicators ? 'tabpanel' : undefined"
      >
        <slot :item="item" :index="index" />
      </div>
    </div>

    <div v-if="arrows && hasOverflow" :class="cn('flex items-center justify-between', ui?.arrows?.wrapper)">
      <slot name="prev" :on-click="onClickPrev" :disabled="isFirst">
        <UiButton
          v-if="prevButton"
          :disabled="isFirst"
          v-bind="{ ...config.carousel.default.prevButton, ...ui?.default?.prevButton, ...prevButton }"
          :class="cn('rtl:[&_span:first-child]:rotate-180 absolute left-4 top-1/2 transform -translate-y-1/2 rounded-full', ui?.default?.prevButton.class, prevButton?.class)"
          aria-label="Prev"
          @click="onClickPrev"
        >
          <Icon :name="prevButtonIcon ?? ui?.default?.prevButtonIcon ?? config.carousel.default.prevButtonIcon" />
        </UiButton>
      </slot>

      <slot name="next" :on-click="onClickNext" :disabled="isLast">
        <UiButton
          v-if="nextButton"
          :disabled="isLast"
          v-bind="{ ...config.carousel.default.nextButton, ...ui?.default?.nextButton, ...nextButton }"
          :class="cn('rtl:[&_span:last-child]:rotate-180 absolute right-4 top-1/2 transform -translate-y-1/2 rounded-full', ui?.default?.nextButton.class, nextButton?.class)"
          aria-label="Next"
          @click="onClickNext"
        >
          <Icon :name="nextButtonIcon ?? ui?.default?.nextButtonIcon ?? config.carousel.default.nextButtonIcon" />
        </UiButton>
      </slot>
    </div>

    <div v-if="indicators" role="tablist" :class="cn('absolute flex items-center justify-center gap-3 bottom-4 inset-x-0', ui?.indicators?.wrapper)">
      <template v-for="page in pages" :key="page">
        <slot name="indicator" :on-click="onClick" :active="page === currentPage" :page="page">
          <button
            type="button"
            role="tab"
            :aria-selected="page === currentPage"
            :class="[
              cn('rounded-full h-3 w-3', ui?.indicators?.base),
              page === currentPage ? cn('bg-primary-500 dark:bg-primary-400', ui?.indicators?.active) : cn('bg-gray-100 dark:bg-gray-800', ui?.indicators?.inactive),
            ]"
            :aria-label="`set slide ${page}`"
            @click="onClick(page)"
          />
        </slot>
      </template>
    </div>
  </div>
</template>
